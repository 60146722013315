import * as React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Image from "gatsby-image"

import { CONTENT_SIZE } from "style/size"
import { COLOR_MAIN, COLOR_WHITE } from "style/colors"

import { Layout } from "components/Layout"

import { Title } from "components/text/Title"
import { ContentPortableText } from "components/blockContent/ContentPortableText"
//import { Text as BaseText } from "components/text/Text"
//import { TitleSmall } from "components/text/TitleSmall"
//import { Bold } from "components/text/Bold"
import { Package } from "../components/general/Package"

const Content = styled.div`
	padding: 0 24px;
	margin: 0 auto;
	margin-bottom: ${props => (props.noMargin ? "0" : "40px")};
	max-width: ${CONTENT_SIZE}px;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: stretch;
`

const Row = styled.div`
	display: flex;
	flex-direction: column-reverse;
	> *:not(:last-child) {
		margin-bottom: 24px;
	}
	@media screen and (min-width: 800px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 64px;
		margin-bottom: 24px;
	}
`
const BreadcrumbContainer = styled.div`
	background-color: ${COLOR_MAIN};
	color: ${COLOR_WHITE};
	width: 100%;
	text-align: center;
	padding-bottom: 1em;
`
const StyledLink = styled(Link)`
	color: ${COLOR_WHITE};
`
const Packages = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 32px;
`
const Spacing = styled.span`
	padding-left: .1em;
`

/**
 * Teams needs to be a template since the slug is dynamic
 * @param {*} param0
 * @returns
 */
const TemplatePackages = ({ data: { sanityPackages } }) => {
	console.log({ sanityPackages })

	const {
		title,
		_rawContent,
		meta,
		order_link,
		packages,
		extra_packages,
		image,
	} = sanityPackages || {}

	return (
		<Layout meta={meta}>
			<BreadcrumbContainer>
				<StyledLink to="/" >Hjem</StyledLink> <Spacing/> / <Spacing>{title}</Spacing>
			</BreadcrumbContainer>
			<Content>
				<Title>{title}</Title>
				<Row>
					<Image
						fluid={image?.image?.asset?.fluid}
						alt={image?.alt}
					/>
					<ContentPortableText blocks={_rawContent} />
				</Row>
				<Packages>
					{packages.map(({ _rawText, ...rest }) => {
						return (
							<Package
								text={_rawText}
								{...rest}
								links={[order_link]}
							/>
						)
					})}
					<Package
						highlight
						title={extra_packages?.title}
						text={extra_packages?._rawText}
						links={extra_packages?.buttons}
					/>
				</Packages>
			</Content>
		</Layout>
	)
}

export const query = graphql`
	query GetPackages {
		sanityPackages {
			title
			_rawContent
			meta {
				title
				description
			}
			order_link {
				label
				_rawData(resolveReferences: { maxDepth: 10 })
			}
			packages {
				title
				price
				binding
				highlight
				_rawText(resolveReferences: { maxDepth: 10 })
			}
			extra_packages {
				title
				_rawText
				buttons {
					label
					_rawData(resolveReferences: { maxDepth: 10 })
				}
			}
			image {
				alt
				image {
					asset {
						fluid {
							srcWebp
							srcSetWebp
							srcSet
							src
							sizes
							base64
							aspectRatio
						}
					}
				}
			}
		}
	}
`

export default TemplatePackages
