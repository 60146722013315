import React from "react"
import styled, { css } from "styled-components"

import { STYLE } from "style"
import { COLOR_MAIN, COLOR_WHITE, COLOR_DARK } from "style/colors"

import { ContentPortableText } from "components/blockContent/ContentPortableText"
import { PopTitle } from "components/text/PopTitle"
import { getLinkPropsFromRawData } from "../../linkFunctions"
import { Button } from "../interactable/Button"

const Wrapper = styled.div`
	color: ${props => (props.highlight ? COLOR_WHITE : COLOR_DARK)};
	border-radius: 6px;
	padding: 32px;
	background-color: #f5f8fd;
	${props =>
		props.highlight &&
		css`
			background: linear-gradient(180deg, #00587c 29.22%, #00384f 155.85%),
				#00587c;
		`};
	> p {
		${STYLE.TEXT.MOBILE.TEXT}
		text-align: left;
	}
	display: flex;
	flex-direction: column;
`

const Name = styled(PopTitle)`
	color: ${props => (props.highlight ? COLOR_WHITE : COLOR_MAIN)};
`

const Binding = styled.p`
	${STYLE.TEXT.MOBILE.TEXT}
	text-align: center;
`

const Center = styled.div`
	> * {
		text-align: center;
	}
`

const Price = styled.p`
	font-family: Inter;
	font-style: normal;
	font-weight: 900;
	font-size: 55px;
	line-height: 52px;
	text-align: center;
	color: ${props => (props.highlight ? COLOR_WHITE : COLOR_MAIN)};
`

const LinksWrapper = styled.div`
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-end;
	> * {
		width: 100%;
	}
	> *:not(:last-child) {
		margin-bottom: 24px;
	}
`

const Links = ({ links }) => {
	console.log({ links })
	const linkMap = links?.map(({ _rawData, label }) => {
		return <Button {...getLinkPropsFromRawData(_rawData)}>{label}</Button>
	})
	if (links.length <= 0) return null
	return <LinksWrapper>{linkMap}</LinksWrapper>
}

export const Package = ({
	title,
	price,
	binding,
	text,
	highlight,
	links = [],
}) => {
	return (
		<Wrapper highlight={highlight}>
			<Center>
				<Name highlight={highlight}>{title}</Name>
				{binding && <Binding>{binding}</Binding>}
				{price && <Price highlight={highlight}>{price},-</Price>}
			</Center>
			<ContentPortableText blocks={text} />
			<Links links={links} />
		</Wrapper>
	)
}
